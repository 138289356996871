<template>
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45" />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
svg {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;
  circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke: #5C2DD3;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
.sk-cube-grid {
  width: 24px;
  height: 24px;
  .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #5C2DD3;
    float: left;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    &.sk-cube1,
    &.sk-cube9,
    &.sk-cube5 {
      animation-delay: 0.2s;
    }
    &.sk-cube2,
    &.sk-cube6 {
      animation-delay: 0.3s;
    }
    &.sk-cube3 {
      animation-delay: 0.4s;
    }
    &.sk-cube4,
    &.sk-cube8 {
      animation-delay: 0.1s;
    }
    &.sk-cube7 {
      animation-delay: 0s;
    }
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
</style>
